(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.guests.view.controller:ViewCtrl
   *
   * @description
   *
   */
  angular
    .module('events.guests.view')
    .controller('ViewCtrl', ViewCtrl);

  function ViewCtrl() {
    var vm = this;
  }
}());
